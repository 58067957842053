.list {
  --be-list-color: var(--bulma-text);
  --be-list-item-description-color: var(--bulma-text-50);
  --be-list-item-divider-color: var(--bulma-border);
  --be-list-item-hover-color: var(--bulma-scheme-main-bis);
  --be-list-item-image-margin: .75em;
  --be-list-item-padding: .75em;
  --be-list-item-title-color: var(--bulma-text-strong);
  --be-list-item-title-weight: var(--bulma-weight-semibold);
  color: var(--be-list-color);
  flex-direction: column;
  display: flex;
}

.list.has-hidden-images .list-item-image {
  display: none;
}

.list.has-hoverable-list-items .list-item:hover {
  background-color: var(--be-list-item-hover-color);
}

.list.has-overflow-ellipsis .list-item-content {
  min-inline-size: 0;
  max-inline-size: calc(var(--length)  * 1ch);
}

.list.has-overflow-ellipsis .list-item-content > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (hover: hover) {
  .list:not(.has-visible-pointer-controls) .list-item-controls {
    opacity: 0;
    visibility: hidden;
  }
}

.list .list-item {
  align-items: center;
  transition: background-color .125s ease-out;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .list .list-item:hover .list-item-controls, .list .list-item:focus-within .list-item-controls {
    opacity: initial;
    visibility: initial;
  }
}

.list .list-item:not(.box) {
  padding-block: var(--be-list-item-padding);
  padding-inline: var(--be-list-item-padding);
}

.list .list-item:not(:last-child):not(.box) {
  border-block-end: 1px solid var(--be-list-item-divider-color);
}

@media screen and (max-width: 768px) {
  .list:not(.has-overflow-ellipsis) .list .list-item {
    flex-wrap: wrap;
  }
}

.list .list-item-image {
  flex-shrink: 0;
  margin-inline-end: var(--be-list-item-image-margin);
}

@media screen and (max-width: 768px) {
  .list .list-item-image {
    padding-block: .5rem;
    padding-inline: 0;
  }
}

.list .list-item-content {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

@media screen and (max-width: 768px) {
  .list .list-item-content {
    padding-block: .5rem;
    padding-inline: 0;
  }
}

.list .list-item-title {
  color: var(--be-list-item-title-color);
  font-weight: var(--be-list-item-title-weight);
}

.list .list-item-description {
  color: var(--be-list-item-description-color);
}

.list .list-item-controls {
  flex-shrink: 0;
  transition: opacity .125s ease-out;
}

@media screen and (max-width: 768px) {
  .list .list-item-controls {
    flex-wrap: wrap;
    padding-block: .5rem;
    padding-inline: 0;
  }
}

@media screen and (min-width: 769px), print {
  .list .list-item-controls {
    padding-inline-start: var(--be-list-item-padding);
  }

  .list:not(.has-visible-pointer-controls) .list .list-item-controls {
    block-size: 100%;
    align-items: center;
    padding-block-end: var(--be-list-item-padding);
    display: flex;
    position: absolute;
    inset-inline-end: 0;
  }
}

/*# sourceMappingURL=bulma-list.css.map */
